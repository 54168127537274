import React, {useState, useEffect} from "react";
import Image1 from "../../../imgs/noAdvert/image1.jpg";
import Image2 from "../../../imgs/noAdvert/image2.jpg";
import Image3 from "../../../imgs/noAdvert/image3.jpg";
import Image4 from "../../../imgs/noAdvert/image4.jpg";
import Image5 from "../../../imgs/noAdvert/image5.jpg";
import FooterImg from "../../../imgs/noAdvert/footer.jpg";

export const NoAdvert = ({hide}) => {
  const mainurl = "https://docs.google.com/forms/d/1tFPU83CReBherIGsxuvJnk_eBXuT8CSe_mG7B9_w148/viewform?edit_requested=true#responses";
  let indexImg = 0;
  const images = [
    { src: Image1, link: mainurl },
    { src: Image2, link: mainurl },
    { src: Image3, link: mainurl },
    { src: Image4, link: mainurl },
    { src: Image5, link: 'mailto:menarini@medinformation.co.uk' }
  ];
  const [activeImg, setImg] = useState(images[indexImg]);

  const updateImg = () => {
    indexImg = (indexImg + 1) % images.length;
    setImg(images[indexImg]);
  };

  useEffect(() => {
    const interval = setInterval(updateImg, 4000);

    return () => clearInterval(interval);
  }, []);

  return !hide && (
    <figure style={{
      maxWidth: '300px',
      margin: '0 auto',
      padding: 0,
    }}>
      <a
        href={activeImg.link}
        target="_blank"
      >
        <img
          src={activeImg.src}
          alt="Slideshow Image"
          style={{
            display: 'block',
            width: '100%',
            height: 'auto',
          }}
        />
      </a>
      <a href="https://online.flippingbook.com/view/364807087/">
        <img
          src={FooterImg}
          alt="PI"
          style={{
            display: 'block',
            width: '100%',
            height: 'auto',
          }}
        />
      </a>
    </figure>
  );
};