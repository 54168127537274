import React, {useState} from 'react';
import {IMAGE_URL} from '../../../constants';
import ReactHtmlParser from 'react-html-parser';
import {NoAdvert} from "./NoAdvert";
import ImageIcon from '../../../imgs/image-icon.svg';
import PDFIcon from '../../../imgs/pdf-icon.svg';
import gifPlaceholder from '../../../imgs/gifPlaceholder.jpg';

const LOREAL_IDS = {
  36: 'Acne',
  234: 'Acne',
  40: 'Actinic Keratosis',
  33: 'Atopic Eczema',
  34: 'Contact Dermatitis',
  162: 'Psoriasis',
  39: 'Seborrhoeic Dermatitis',
};

const ConsultationInfo = ({
  consultation,
  title,
  className,
  description,
  name,
  user,
  onSponsorClick,
  attachmentName,
  attachments,
  sponsor,
  setStatistic,
}) => {
  const [loading, setLoading] = useState(true);
  const isGif = consultation?.sponsor_logo_url.includes('.gif') && consultation.id === 288;
  const isMob = window.innerWidth < 768;
  const linkView = () => {
    return LOREAL_IDS[consultation.id] ? (
      <p>{`To learn more about ${LOREAL_IDS[consultation.id]} click on the image:`}</p>
    ) : null;
  };
  const handleStatistic = () => {
    if (setStatistic)
      setStatistic({
        consultation_id: consultation.id,
        statistic_type: 0,
        button_name: LOREAL_IDS[consultation.id] || consultation.title,
      });
  };
  const onLoad = () => {
    setLoading(false);
  };

  return (
    title ? (
      <div className="editor-title">
        <p className='title-text consultatation-details-title'>{ ReactHtmlParser(title) }</p>
        { consultation.is_sponsored && user.user_type !== 'practice_nurse' &&
          <a
            href={ `${ consultation.sponsor_url ? consultation.sponsor_url : '' }` }
            target='_blank'
            rel="noopener noreferrer"
            onClick={ handleStatistic }
          >
            <div className="navigation-sponsor">
              <div className='support-logo'>
                {isGif ? (
                  <>
                    {loading && (
                      <img src={ gifPlaceholder } alt="support logo" />
                    )}
                    <img
                      src={ `${ IMAGE_URL }${ consultation.sponsor_logo_url }` }
                      alt="support logo"
                      onLoad={onLoad}
                      style={{visibility: loading ? 'hidden' : 'visible'}}
                    />
                  </>
                ) : (
                  <img src={ `${ IMAGE_URL }${ consultation.sponsor_logo_url }` } alt="support logo" />
                )}
              </div>
              {linkView()}
              {/* <div className='sponsor-title'>
                <p>{ 'Supported by: ' }</p>
              </div> */}
            </div>
          </a>
        }
        { sponsor && !consultation.is_sponsored && user.user_type !== 'practice_nurse' &&
          <a
            href={ `${ sponsor.url ? sponsor.url : '' }` }
            target='_blank'
            rel="noopener noreferrer"
            onClick={ onSponsorClick }
            // onClick={ handleStatistic }
          >
            <div className="navigation-sponsor">
              <div className='support-logo'>
                <img src={ `${ IMAGE_URL }${ sponsor.sponsor_logo_url }` } alt="support logo" />
              </div>
              {/* <div className='sponsor-title'>
                <p>{ 'Supported by: ' }</p>
              </div> */}
            </div>
          </a>
        }
        {(!consultation.is_sponsored && !sponsor && user.user_type !== 'practice_nurse') && (
          <NoAdvert hide={!isMob} />
        )}
      </div>
    ) : ( description &&
      <div style={{marginBottom: '40px'}}>
        <div className={`editor-${ className } viewer-w`}>
          <div className="editor-w--label">{ name }</div>
          <div className="viewer--viewer">
            { ReactHtmlParser(description) }
          </div>
        </div>
        <div className="consultation--attachments">
          { attachments &&
            attachments.map(attachment => (
              attachmentName === attachment.section ?
              <a key={ attachment.id } href={ `${ IMAGE_URL }${ attachment.attachment_url }` } target="_blank" rel="noopener noreferrer">
                <div className="attachment-single">
                  <img src={ attachment.document_file_name.match(/^.+\.(([pP][dD][fF]))$/) ? PDFIcon : ImageIcon } alt="file icon" />
                  <span>{ attachment.document_file_name }</span>
                </div>
              </a> : null
            )
          )}
        </div>
      </div>
    )
  );
}

export default ConsultationInfo;